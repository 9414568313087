import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import LeaveAction from "../../stores/action/leaveAction";
import moment from "moment";
import swal from "sweetalert";
import TableFilter from "../../components/TableFilter";
import Breadcrumb from "../../components/Breadcrumb";

function LeaveRequestList() {
  const [loading, setLoading] = useState<boolean>(false);

  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [type, setType] = useState<any>("PENDING");
  const dispatch = useDispatch<any>();

  const {
    leaveRequestList,
    pending,
    approved,
    rejected,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
  } = useSelector((state: RootState) => state.leave);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getLeaveRequest = useCallback(async () => {
    await dispatch(
      LeaveAction.getLeaveRequestList({
        limit: limit,
        search: search,
        page: page,
        type: type,
      }),
    );
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getLeaveRequest();
  }, [getLeaveRequest, search, limit, page, type]);

  const handleRemove = async (id: number) => {
    const status = await swal({
      title: "Leave Application",
      // text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        confirm: {
          text: "Approve",
          value: "APPROVED",
          visible: true,
          className: "approved-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
        rejected: {
          text: "Reject",
          value: "REJECTED",
          visible: true,
          className: "delete-button", // Custom class for styling
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: "cancel",
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
      },
    });

    if (status !== "cancel") {
      try {
        setLoading(true);
        await dispatch(LeaveAction.updateLeaveRequest({ id, status }));
        swal("Leave " + status.toLowerCase() + "!", {
          icon: "success",
        });
        getLeaveRequest();
      } catch (error) {
        console.log({ error });
        swal("Error!", "There was an issue update the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <WebLayout pageName={"LeaveRequestList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Leave"} subTitle={"Leave Applications"} />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header d-flex justify-content-between">
                  <div>
                    <button
                      className="btn btn-warning"
                      onClick={() => setType("PENDING")}
                    >
                      Pending List{" "}
                      <span className="badge" style={{ top: 0 }}>
                        {pending}
                      </span>
                    </button>{" "}
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-success"
                      onClick={() => setType("APPROVED")}
                    >
                      Approved List{" "}
                      <span className="badge" style={{ top: 0 }}>
                        {approved}
                      </span>
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => setType("REJECTED")}
                    >
                      Rejected List{" "}
                      <span className="badge" style={{ top: 0 }}>
                        {rejected}
                      </span>
                    </button>
                  </div>
                </div>
                <TableFilter
                  limit={limit}
                  set_limit={set_limit}
                  search={search}
                  set_search={set_search}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Employee Id</th>
                          <th> Name</th>
                          <th> Date</th>
                          <th> Day</th>
                          <th> Leave Type</th>
                          <th> Reason</th>
                          <th> Applied On</th>
                          <th> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveRequestList?.length > 0 ? (
                          leaveRequestList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {dataList.employeeId
                                      ? dataList.employeeId
                                      : "N/A"}
                                  </td>
                                  <td>{dataList.name}</td>
                                  <td>
                                    From :{" "}
                                    {moment(dataList.start_date).format(
                                      "D-MMM-YYYY",
                                    )}
                                    <br />
                                    To :{" "}
                                    {moment(dataList.end_date).format(
                                      "D-MMM-YYYY",
                                    )}
                                  </td>
                                  <td>{dataList.days}</td>
                                  <td>{dataList.leave_type}</td>
                                  <td>
                                    {dataList.reason ? dataList.reason : "N/A"}
                                  </td>
                                  <td>
                                    {moment(dataList.created_at).format(
                                      "D-MMM-YYYY",
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => handleRemove(dataList.id)}
                                      disabled={loading || type !== "PENDING"}
                                    >
                                      {loading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        "Action"
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {leaveRequestList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p: any, index: number) => {
                              return (
                                <li
                                  key={index}
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}
                                >
                                  <a
                                    className="page-link"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0"
                                  >
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default LeaveRequestList;
