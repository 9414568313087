const DesignationType = {
  CREATE_DESIGNATION: "CREATE_DESIGNATION",
  GET_DESIGNATION_LIST: "GET_DESIGNATION_LIST",
  REMOVE_DESIGNATION: "REMOVE_DESIGNATION",
  UPDATE_DESIGNATION: "UPDATE_DESIGNATION",
};

export interface CreateDesignation {
  name: string;
}

export interface GetDesignation {
  search: string;
  limit: number;
  page: number;
}

export interface UpdateDesignation {
  name: string;
  id: number;
}

export interface RemoveDesignation {
  id: number;
}

export default DesignationType;
