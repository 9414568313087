import ProfileType from "../type/profileType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  profileData: null,
  getStateList: [],
  getDistrictList: [],
};

const ProfileReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case ProfileType.SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };

    case ProfileType.UPDATE_PROFILE_DATA:
      return {
        ...state,
      };

    case ProfileType.UPDATE_BANK_DETAILS:
      return {
        ...state,
      };

    case ProfileType.UPDATE_KYC_DETAILS:
      return {
        ...state,
      };

    case ProfileType.GET_STATE_LIST:
      return {
        ...state,
        getStateList: action.payload.data,
      };

    case ProfileType.GET_DISTRICT_LIST:
      return {
        ...state,
        getDistrictList: action.payload.data,
      };

    default:
      return state;
  }
};

export default ProfileReducers;
