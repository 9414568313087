import LeaveType from "../type/leaveType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  leaveList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  fetchLeaveList: [],
  leaveRequestList: [],
  pending: 0,
  approved: 0,
  rejected: 0,
};

const LeaveTypeReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case LeaveType.GET_LEAVE_LIST:
      return {
        ...state,
        leaveList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case LeaveType.CREATE_LEAVE:
      return {
        ...state,
      };

    case LeaveType.UPDATE_LEAVE:
      return {
        ...state,
      };

    case LeaveType.REMOVE_LEAVE:
      return {
        ...state,
      };

    case LeaveType.FETCH_LEAVE_LIST:
      return {
        ...state,
        fetchLeaveList: action.payload,
      };

    case LeaveType.GET_LEAVE_REQUEST_LIST:
      return {
        ...state,
        leaveRequestList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        pending: action.payload.pending,
        approved: action.payload.approved,
        rejected: action.payload.rejected,
      };

    case LeaveType.UPDATE_LEAVE_REQUEST:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default LeaveTypeReducers;
