import SalaryType from "../type/salaryType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  salaryList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  salaryStructure: {},
};

const SalaryReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case SalaryType.SALARY_GENERATE_LIST:
      return {
        ...state,
        salaryList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case SalaryType.GET_SALARY_STRUCTURE:
      return {
        ...state,
        salaryStructure: action.payload,
      };

    case SalaryType.SUBMIT_SALARY_STRUCTURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default SalaryReducers;
