import {useState} from "react";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import EmployerAction from "../../stores/action/employerAction";
import toast from "react-hot-toast";

type FormData = {
    currentPassword: any;
    newPassword: any;
    confirmPassword: any;
};

function ChangePassword({editableData}: any) {
    const dispatch = useDispatch<any>();

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<FormData>();

    const [btnLoading, setBtnLoading] = useState(false);

    const formHandleSubmit = async (data: any) => {
        setBtnLoading(true);
        try {
            await dispatch(
                EmployerAction.changePassword({
                    currentPassword: data.currentPassword,
                    newPassword: data.newPassword,
                    confirmPassword: data.confirmPassword,
                }),
            );
            setBtnLoading(false);
            reset();
        } catch (error: any) {
            setBtnLoading(false);
            toast.error(error, {
                className: "custom-toast",
            });
        }
    };

    return (
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card project_widget">
                    <div className="header">
                        <h2>CHANGE PASSWORD</h2>
                    </div>
                    <div className="body">
                        <form
                            className="needs-validation"
                            onSubmit={handleSubmit(formHandleSubmit)}
                            encType="multipart/form-data"
                        >
                            <div className="row clearfix">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>
                                            Current Password <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Current Password"
                                            {...register("currentPassword", {required: true})}
                                        />
                                        {errors.currentPassword && (
                                            <span className="text-danger">
                        Current password is required
                      </span>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>
                                            New Password <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="New Password"
                                            {...register("newPassword", {required: true})}
                                        />
                                        {errors.newPassword && (
                                            <span className="text-danger">New password is required</span>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>
                                            Confirm Password <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            {...register("confirmPassword", {required: true})}
                                        />
                                        {errors.confirmPassword && (
                                            <span className="text-danger">
                        Confirm password is required
                      </span>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <button
                                        className="btn btn-dark btn-round"
                                        disabled={btnLoading}
                                    >
                                        {btnLoading ? (
                                            <i className={"fa fa-spinner fa-spin"}></i>
                                        ) : null}
                                        &nbsp; Save Changes
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
