import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";
import EmployerAction from "../../stores/action/employerAction";
import toast from "react-hot-toast";

type FormData = {
  companyImage: any;
  companyName: string;
  noOfEmployees: any;
  email: any;
  phone: number;
  typeOfCompany: any;
  state: any;
  district: any;
  pinCode: number;
  address: any;
};

function BasicDetails({ editableData, getEmployerData }: any) {
  const dispatch = useDispatch<any>();

  const { stateList, districtList, companyTypeList } = useSelector(
    (state: RootState) => state.web,
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FormData>();

  const [btnLoading, setBtnLoading] = useState(false);

  // Watch state for updates
  const selectedState = watch("state");
  const selectedDistrict = watch("district");
  const selectedTypeOfCompany = watch("typeOfCompany");

  const getDistrict = useCallback(
    async (state_id: number) => {
      try {
        await dispatch(WebAction.getDistrict({ state_id }));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setValue("companyImage", editableData?.company_image);
    setValue("companyName", editableData?.name);
    setValue("noOfEmployees", editableData?.no_of_employee);
    setValue("email", editableData?.email);
    setValue("phone", editableData?.mobile);
    setValue("typeOfCompany", editableData?.type_of_company);
    setValue("state", editableData?.state);
    setValue("district", editableData?.district);
    setValue("pinCode", editableData?.pincode);
    setValue("address", editableData?.address);

    if (editableData?.state) {
      getDistrict(editableData?.state);
    }
  }, [editableData, getDistrict, setValue]);

  const handleStateChange = async (event: any) => {
    const stateId = event.target.value;
    setValue("state", stateId);
    await getDistrict(stateId);
  };

  const formHandleSubmit = async (data: any) => {
    setBtnLoading(true);
    try {
      await dispatch(
        EmployerAction.updateEmployer({
          companyImage: data.companyImage ? data.companyImage[0] : null,
          name: data.companyName,
          no_of_employee: data.noOfEmployees,
          email: data.email,
          mobile: data.phone,
          typeOfCompany: data.typeOfCompany,
          state: data.state,
          district: data.district,
          pincode: data.pinCode,
          address: data.address,
        }),
      );
      setBtnLoading(false);
      reset();

      getEmployerData();
    } catch (error: any) {
      setBtnLoading(false);
      toast.error(error, {
        className: "custom-toast",
      });
    }
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card project_widget">
          <div className="header">
            <h2>BASIC DETAILS</h2>
          </div>
          <div className="body">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(formHandleSubmit)}
              encType="multipart/form-data"
            >
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>Company Image</label>
                    <input
                      type="file"
                      className="form-control"
                      accept={"image/*"}
                      {...register("companyImage")}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      Company Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      {...register("companyName", { required: true })}
                    />
                    {errors.companyName && (
                      <span className="text-danger">
                        Company Name is required
                      </span>
                    )}
                  </div>
                </div>

                {/* No. of Employees */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      No. of Employees <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="No. of Employees"
                      {...register("noOfEmployees", { required: true })}
                    />
                    {errors.noOfEmployees && (
                      <span className="text-danger">
                        No. of Employees is required
                      </span>
                    )}
                  </div>
                </div>

                {/* Email */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="E-mail"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span className="text-danger">Email is required</span>
                    )}
                  </div>
                </div>

                {/* Phone */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      {...register("phone", { required: true })}
                      maxLength={10}
                    />
                    {errors.phone && (
                      <span className="text-danger">Phone is required</span>
                    )}
                  </div>
                </div>

                {/* Type Of Company */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      Select Type Of Company
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className={"form-control"}
                      {...register("typeOfCompany", { required: true })}
                      value={selectedTypeOfCompany}
                    >
                      <option value="" disabled>
                        Select Type Of Company
                      </option>
                      {companyTypeList?.map((val: any, index: number) => (
                        <option key={index} value={val.name}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                    {errors.typeOfCompany && (
                      <span className="text-danger">
                        Type of Company is required
                      </span>
                    )}
                  </div>
                </div>

                {/* State */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      State <span className="text-danger">*</span>
                    </label>
                    <select
                      className={"form-control"}
                      {...register("state", { required: true })}
                      onChange={handleStateChange}
                      value={selectedState}
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      {stateList?.map((val: any, index: number) => (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <span className="text-danger">State is required</span>
                    )}
                  </div>
                </div>

                {/* District */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      District <span className="text-danger">*</span>
                    </label>
                    <select
                      className={"form-control"}
                      {...register("district", { required: true })}
                      value={selectedDistrict}
                    >
                      <option value="" disabled>
                        Select District
                      </option>
                      {districtList?.map((val: any, index: number) => (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                    {errors.district && (
                      <span className="text-danger">District is required</span>
                    )}
                  </div>
                </div>

                {/* Pincode */}
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label>
                      Pincode <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                      {...register("pinCode", { required: true })}
                      maxLength={6}
                    />
                    {errors.pinCode && (
                      <span className="text-danger">Pincode is required</span>
                    )}
                  </div>
                </div>

                {/* Address */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control no-resize"
                      placeholder="Address"
                      {...register("address", { required: true })}
                    ></textarea>
                    {errors.address && (
                      <span className="text-danger">Address is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    className="btn btn-dark btn-round"
                    disabled={btnLoading}
                  >
                    {btnLoading ? (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    ) : null}
                    &nbsp; Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicDetails;
