import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useForm } from "react-hook-form";
import DesignationAction from "../../stores/action/designationAction";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb";
import TableFilter from "../../components/TableFilter";

type FormData = {
  name: string;
};

function Designation() {
  const [search, set_search] = useState<string>("");
  const [limit, set_limit] = useState<number>(10);
  const [page, set_page] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<number | null>(null); // State to track which row is being edited
  const [editedName, setEditedName] = useState<string>(""); // State for the new name input field

  const dispatch = useDispatch<any>();

  const { designationList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.designation);

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getDesignationList = useCallback(async () => {
    await dispatch(
      DesignationAction.getDesignationList({
        limit: limit,
        search: search,
        page: page,
      }),
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getDesignationList();
  }, [getDesignationList]);

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        DesignationAction.createDesignation({
          name: data.name,
        }),
      );
      reset();
      setLoading(false);
      set_page(1); // Optionally reset to first page after adding
      getDesignationList();
    } catch (error) {
      console.error("Error creating designation:", error);
      setLoading(false);
      // Optionally, display an error message to the user
    }
  };

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(DesignationAction.removeDesignation({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        getDesignationList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (id: number, currentName: string) => {
    setEditId(id); // Set the current row for editing
    setEditedName(currentName); // Set the current name in the input field
  };

  // Handle update
  const handleUpdate = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        DesignationAction.updateDesignation({
          id: id,
          name: editedName,
        }),
      );
      setEditId(null); // Close the edit mode after updating
      getDesignationList();
    } catch (error) {
      console.error("Error updating designation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebLayout pageName={"Designation"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Designation"} subTitle={"Designations"} />
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="card">
                <div className="body">
                  <div className="row clearfix">
                    <form
                      className="needs-validation"
                      onSubmit={methods.handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Designation Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Designation Name"
                            {...register("name", { required: true })}
                          />
                          {errors.name && (
                            <span className="text-danger">
                              LeaveType Name is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-dark ml-auto"
                          disabled={loading}
                        >
                          {loading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Holiday List */}
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="card">
                <TableFilter
                  limit={limit}
                  set_limit={set_limit}
                  search={search}
                  set_search={set_search}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className={"ubuntu-medium"}>Name</th>
                          <th className={"ubuntu-medium"}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {designationList?.length > 0 ? (
                          designationList.map(
                            (dataList: any, index: number) => (
                              <tr key={dataList.id}>
                                <td>{(page - 1) * limit + index + 1}</td>
                                <td>
                                  {editId === dataList.id ? (
                                    <input
                                      type="text"
                                      value={editedName}
                                      onChange={(e) =>
                                        setEditedName(e.target.value)
                                      }
                                    />
                                  ) : (
                                    dataList.name
                                  )}
                                </td>
                                <td>
                                  {editId === dataList.id ? (
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={() => handleUpdate(dataList.id)}
                                    >
                                      Update
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() =>
                                          handleEdit(dataList.id, dataList.name)
                                        }
                                      >
                                        Edit
                                      </button>
                                      &nbsp;
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          handleRemove(dataList.id)
                                        }
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        ) : (
                                          "Remove"
                                        )}
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ),
                          )
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {designationList?.length > 0 && (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            {/* First Page */}
                            <li
                              className={`dt-paging-button page-item ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() => page !== 1 && set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled={page === 1}
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>

                            {/* Previous Page */}
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() => page !== 1 && set_page(page - 1)}
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled={page === 1}
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>

                            {/* Page Numbers */}
                            {pagination.map((p: number) => (
                              <li
                                key={p}
                                className={`dt-paging-button page-item ${
                                  page === p ? "active" : ""
                                }`}
                                onClick={() => set_page(p)}
                              >
                                <a
                                  className="page-link"
                                  aria-controls="tableGroup"
                                  aria-current="page"
                                  data-dt-idx="0"
                                >
                                  {p}
                                </a>
                              </li>
                            ))}

                            {/* Next Page */}
                            <li
                              className={`dt-paging-button page-item next ${
                                page === lastPage ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page !== lastPage && set_page(page + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>

                            {/* Last Page */}
                            <li
                              className={`dt-paging-button page-item ${
                                page === lastPage ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page !== lastPage && set_page(lastPage)
                              }
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-disabled={page === lastPage}
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Designation;
