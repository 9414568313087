import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { EMPLOYER_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EmployerType, {
  UpdateBankDetails,
  UpdateBasicDetails,
  UpdateEmployerKYC,
  ChangePassword,
} from "../type/employerType";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EmployerAction = {
  getEmployerDetails:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.get(BASE_URL + "profile", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
          },
        });

        if (result.data.status) {
          dispatch({
            type: EmployerType.GET_EMPLOYER_DETAILS,
            payload: result.data.data,
          });
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateEmployer:
    (data: UpdateBasicDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "update/basicDetails",
          {
            company_image: data.companyImage,
            name: data.name,
            no_of_employee: data.no_of_employee,
            email: data.email,
            mobile: data.mobile,
            typeOfCompany: data.typeOfCompany,
            state: data.state,
            district: data.district,
            pincode: data.pincode,
            address: data.address,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployerType.UPDATE_EMPLOYER,
          });
          toast.success(result.data.msg, {
            className: "custom-toast",
          });
        } else {
          toast.error(result.data.msg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateBankDetails:
    (data: UpdateBankDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "update/bankDetails",
          {
            bankName: data.bankName,
            branchName: data.branchName,
            accountHolderName: data.accountHolderName,
            ifscCode: data.ifscCode,
            accountNumber: data.accountNumber,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployerType.UPDATE_EMPLOYER_BANK_DETAILS,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  changePassword:
    (data: ChangePassword): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "changePassword",
          {
            old_password: data.currentPassword,
            new_password: data.newPassword,
            new_password_confirmation: data.confirmPassword,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployerType.CHANGE_PASSWORD,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateEmployerKYC:
    (data: UpdateEmployerKYC): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "update/kycDetails",
          {
            gst_no: data.gst_no,
            gst_img: data.gst_img,
            pancard_no: data.pancard_no,
            pancard_img: data.pancard_img,
            msme_no: data.msme_no,
            msme_img: data.msme_img,
            partnership_no: data.partnership_no,
            partnership_img: data.partnership_img,
            rc_no: data.rc_no,
            rc_img: data.rc_img,
            moa_no: data.moa_no,
            moa_img: data.moa_img,
            aoa_no: data.aoa_no,
            aoa_img: data.aoa_img,
            coi_no: data.coi_no,
            coi_img: data.coi_img,
            trust_no: data.trust_no,
            trust_img: data.trust_img,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployerType.UPDATE_EMPLOYER_KYC,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default EmployerAction;
