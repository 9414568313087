import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import SalaryAction from "../../stores/action/salaryAction";
import PaymentModal from "../../components/PaymentModal";
import toast from "react-hot-toast";
import TableFilter from "../../components/TableFilter";
import Breadcrumb from "../../components/Breadcrumb";

function SalaryGenerateList() {
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const { salaryList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.salary);

  const maxPagesToShow = 5;
  const pagination = [];
  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);
  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getSalaryRequest = useCallback(async () => {
    await dispatch(
      SalaryAction.getSalaryGenerate({
        limit,
        search,
        page,
      }),
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getSalaryRequest();
  }, [getSalaryRequest, search, limit, page]);

  const handleSelectRow = (id: number) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === salaryList.length);
  };

  const handleSelectAll = () => {
    // Check if any unpaid rows exist
    const unpaidRows = salaryList
      .filter((data: any) => !data.salaryPaid)
      .map((data: any) => data.id);

    if (!selectAll) {
      // If not selected, set selectedRows to only unpaid rows
      setSelectedRows(unpaidRows);
    } else {
      // If already selected, clear selectedRows
      setSelectedRows([]);
    }
    setSelectAll(!selectAll);
  };

  const calculateTotal = (field: string) =>
    selectedRows.reduce((total, rowId) => {
      const selectedData = salaryList.find(
        (dataList: any) => dataList.id === rowId,
      );
      return total + (selectedData ? selectedData.salaryInfo[field] : 0);
    }, 0);

  const totalAmount = calculateTotal("salary");
  const totalAmountReceivable = calculateTotal("amount_receivable");
  const totalServiceFee = calculateTotal("service_fee");
  const totalGstAmount = calculateTotal("gst_amount");
  const paidTotalAmountReceivable = salaryList
    .filter((item: any) => item.salaryPaid)
    .reduce(
      (total: any, item: any) => total + item.salaryInfo.amount_receivable,
      0,
    );

  const openModal = () => {
    if (selectedRows.length === 0) {
      toast.error("Please select at least one row before proceeding.", {
        className: "custom-toast",
      });
      return;
    }
    setModal(true);
  };

  return (
    <WebLayout pageName={"SalaryGenerateList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb
              mainTitle={"Salary"}
              subTitle={"Salary Generate List"}
            />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <TableFilter
                  limit={limit}
                  set_limit={setLimit}
                  search={search}
                  set_search={setSearch}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>#</th>
                          <th>Employee Id</th>
                          <th>Name</th>
                          <th>Salary</th>
                          <th>Service Fee</th>
                          <th>GST Amount</th>
                          <th>Amount Receivable</th>
                          <th>PV</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salaryList.length > 0 ? (
                          salaryList.map((dataList: any, index: number) => (
                            <tr key={dataList.id}>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(dataList.id)}
                                  onChange={() => handleSelectRow(dataList.id)}
                                  disabled={dataList.salaryPaid}
                                />
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {index + 1}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {dataList.employeeId || "N/A"}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {dataList.name}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs. {dataList.salaryInfo.salary}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs. {dataList.salaryInfo.service_fee}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs. {dataList.salaryInfo.gst_amount}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs. {dataList.salaryInfo.amount_receivable}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {dataList.salaryInfo.pv}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {salaryList.length > 0 && (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto">
                        Showing {from} to {to} of {totalData} entries
                      </div>
                      <div className="col-md-auto">
                        <ul className="pagination">
                          <li
                            className={`page-item ${page === 1 ? "disabled" : ""}`}
                            onClick={() => page !== 1 && setPage(1)}
                          >
                            <a className="page-link">&laquo;</a>
                          </li>
                          <li
                            className={`page-item ${page === 1 ? "disabled" : ""}`}
                            onClick={() => page !== 1 && setPage(page - 1)}
                          >
                            <a className="page-link">&lt;</a>
                          </li>
                          {pagination.map((p) => (
                            <li
                              key={p}
                              className={`page-item ${page === p ? "active" : ""}`}
                              onClick={() => setPage(p)}
                            >
                              <a className="page-link">{p}</a>
                            </li>
                          ))}
                          <li
                            className={`page-item ${page === lastPage ? "disabled" : ""}`}
                            onClick={() =>
                              page !== lastPage && setPage(page + 1)
                            }
                          >
                            <a className="page-link">&gt;</a>
                          </li>
                          <li
                            className={`page-item ${page === lastPage ? "disabled" : ""}`}
                            onClick={() =>
                              page !== lastPage && setPage(lastPage)
                            }
                          >
                            <a className="page-link">&raquo;</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row p-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    {totalAmountReceivable > 0 ? (
                      <h5 className={"text-danger"}>
                        Total Amount Payable: Rs. {totalAmountReceivable}
                      </h5>
                    ) : null}
                    {paidTotalAmountReceivable > 0 ? (
                      <h5 className={"text-success"}>
                        Total Amount Paid: Rs. {paidTotalAmountReceivable}
                      </h5>
                    ) : null}

                    {!salaryList.every((data: any) => data.salaryPaid) && (
                      <button
                        className="btn btn-success justify-content-end"
                        onClick={openModal}
                      >
                        PROCEED TO PAYMENT
                      </button>
                    )}
                  </div>
                </div>
                <PaymentModal
                  modal={modal}
                  setModal={setModal}
                  getSalaryRequest={getSalaryRequest}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  setSelectAll={setSelectAll}
                  totalAmount={totalAmount}
                  totalGstAmount={totalGstAmount}
                  totalServiceFee={totalServiceFee}
                  totalAmountReceivable={totalAmountReceivable}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default SalaryGenerateList;
