import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import EmployerAction from "../../stores/action/employerAction";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";

type FormData = {
    gst_no: any;
    gst_img: any;
    pancard_no: any;
    pancard_img: any;
    msme_no: any;
    msme_img: any;
    partnership_no: any;
    partnership_img: any;
    rc_no: any;
    rc_img: any;
    moa_no: any;
    moa_img: any;
    aoa_no: any;
    aoa_img: any;
    coi_no: any;
    coi_img: any;
    trust_no: any;
    trust_img: any;
};

function EditKYCDetails({editableData, getEmployerData}: any) {
    const dispatch = useDispatch<any>();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm<FormData>();

    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        setValue("gst_no", editableData?.gst_no);
        setValue("gst_img", editableData?.gst_img);
        setValue("pancard_no", editableData?.pancard_no);
        setValue("pancard_img", editableData?.pancard_img);
        setValue("msme_no", editableData?.msme_no);
        setValue("msme_img", editableData?.msme_img);
        setValue("partnership_no", editableData?.partnership_no);
        setValue("partnership_img", editableData?.partnership_img);
        setValue("rc_no", editableData?.rc_no);
        setValue("rc_img", editableData?.rc_img);
        setValue("moa_no", editableData?.moa_no);
        setValue("moa_img", editableData?.moa_img);
        setValue("aoa_no", editableData?.aoa_no);
        setValue("aoa_img", editableData?.aoa_img);
        setValue("coi_no", editableData?.coi_no);
        setValue("coi_img", editableData?.coi_img);
        setValue("trust_no", editableData?.trust_no);
        setValue("trust_img", editableData?.trust_img);
    }, [editableData, setValue]);

    const formHandleSubmit = async (data: any) => {
        setBtnLoading(true)
        try {
            await dispatch(
                EmployerAction.updateEmployerKYC({
                    id: editableData.id,
                    gst_no: data.gst_no,
                    gst_img: data.gst_img ? data.gst_img[0] : null,
                    pancard_no: data.pancard_no,
                    pancard_img: data.pancard_img ? data.pancard_img[0] : null,
                    msme_no: data.msme_no,
                    msme_img: data.msme_img ? data.msme_img[0] : null,
                    partnership_no: data.partnership_no,
                    partnership_img: data.partnership_img
                        ? data.partnership_img[0]
                        : null,
                    rc_no: data.rc_no,
                    rc_img: data.rc_img ? data.rc_img[0] : null,
                    moa_no: data.moa_no,
                    moa_img: data.moa_img ? data.moa_img[0] : null,
                    aoa_no: data.aoa_no,
                    aoa_img: data.aoa_img ? data.aoa_img[0] : null,
                    coi_no: data.coi_no,
                    coi_img: data.coi_img ? data.coi_img[0] : null,
                    trust_no: data.trust_no,
                    trust_img: data.trust_img ? data.trust_img[0] : null,
                })
            );
            setBtnLoading(false)
            reset();
            getEmployerData();
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error, {
                className: "custom-toast",
            });
        }
    };

    return (
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                        <h2>
                            <strong>KYC</strong> DOCUMENT
                        </h2>
                        {
                            editableData?.kyc_status === "REJECTED" ?
                                <p className={"text-danger mb-0"}>Rejected Reason: {editableData?.kyc_reason}</p> : null
                        }
                    </div>
                    <div className="body">
                        <form
                            className="needs-validation"
                            encType="multipart/form-data"
                            onSubmit={handleSubmit(formHandleSubmit)}
                        >
                            {editableData?.type_of_company === "Proprietor" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />

                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no", {
                                                    required: true,
                                                })}
                                                placeholder="GST Number"
                                            />
                                            {errors.gst_no && errors.gst_no.type === "required" && (
                                                <span className="text-danger">
                          GST Number is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }

                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img", {
                                                    required: editableData?.gst_img === null,
                                                })}
                                            />
                                            {errors.gst_img && errors.gst_img.type === "required" && (
                                                <span className="text-danger">
                          GST Image is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : editableData?.type_of_company ===
                            "Partnership Firm" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />
                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                                className="form-control"
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no", {
                                                    required: true,
                                                })}
                                                placeholder="GST Number"
                                            />
                                            {errors.gst_no && errors.gst_no.type === "required" && (
                                                <span className="text-danger">
                          GST Number is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img", {
                                                    required: editableData?.gst_img === null,
                                                })}
                                            />
                                            {errors.gst_img && errors.gst_img.type === "required" && (
                                                <span className="text-danger">
                          GST Image is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Partnership Deed Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("partnership_no")}
                                                placeholder="Partnership Deed Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Partnership Deed Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.partnership_img ?
                                                    <Link to={editableData?.partnership_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("partnership_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : editableData?.type_of_company === "LLP" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />
                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                                className="form-control"
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no", {
                                                    required: true,
                                                })}
                                                placeholder="GST Number"
                                            />
                                            {errors.gst_no && errors.gst_no.type === "required" && (
                                                <span className="text-danger">
                          GST Number is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img", {
                                                    required: editableData?.gst_img === null,
                                                })}
                                            />
                                            {errors.gst_img && errors.gst_img.type === "required" && (
                                                <span className="text-danger">
                          GST Image is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                RC Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("rc_no")}
                                                placeholder="RC Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload RC Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.rc_img ?
                                                    <Link to={editableData?.rc_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("rc_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Partnership Deed Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("partnership_no")}
                                                placeholder="Partnership Deed Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Partnership Deed Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.partnership_img ?
                                                    <Link to={editableData?.partnership_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("partnership_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : editableData?.type_of_company === "Company" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />
                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                                className="form-control"
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no", {
                                                    required: true,
                                                })}
                                                placeholder="GST Number"
                                            />
                                            {errors.gst_no && errors.gst_no.type === "required" && (
                                                <span className="text-danger">
                          GST Number is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img", {
                                                    required: editableData?.gst_img === null,
                                                })}
                                            />
                                            {errors.gst_img && errors.gst_img.type === "required" && (
                                                <span className="text-danger">
                          GST Image is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MOA Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("moa_no")}
                                                placeholder="MOA Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MOA Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.moa_img ?
                                                    <Link to={editableData?.moa_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("moa_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                AOA Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("aoa_no")}
                                                placeholder="AOA Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload AOA Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.aoa_img ?
                                                    <Link to={editableData?.aoa_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("aoa_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                COI Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("coi_no")}
                                                placeholder="COI Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload COI Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.coi_img ?
                                                    <Link to={editableData?.coi_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("coi_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : editableData?.type_of_company ===
                            "Any other entity" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />
                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                                className="form-control"
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no", {
                                                    required: true,
                                                })}
                                                placeholder="GST Number"
                                            />
                                            {errors.gst_no && errors.gst_no.type === "required" && (
                                                <span className="text-danger">
                          GST Number is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img", {
                                                    required: editableData?.gst_img === null,
                                                })}
                                            />
                                            {errors.gst_img && errors.gst_img.type === "required" && (
                                                <span className="text-danger">
                          GST Image is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                COI Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("coi_no")}
                                                placeholder="COI Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload COI Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.coi_img ?
                                                    <Link to={editableData?.coi_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("coi_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : editableData?.type_of_company === "Trust" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />
                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                                className="form-control"
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Trust Deed Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("trust_no", {required: true})}
                                                placeholder="Trust Deed Number"
                                            />
                                            {errors.trust_no &&
                                                errors.trust_no.type === "required" && (
                                                    <span className="text-danger">
                            Trust Deed Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload Trust Deed Image{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.trust_img ?
                                                    <Link to={editableData?.trust_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("trust_img", {required: editableData?.trust_img === null})}
                                                className="form-control"
                                            />
                                            {errors.trust_img &&
                                                errors.trust_img.type === "required" && (
                                                    <span className="text-danger">
                            Trust Deed Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no")}
                                                placeholder="GST Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : editableData?.type_of_company === "Society" ? (
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                PAN Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("pancard_no", {required: true})}
                                                placeholder="PAN Number"
                                            />
                                            {errors.pancard_no &&
                                                errors.pancard_no.type === "required" && (
                                                    <span className="text-danger">
                            PAN Number is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload PAN Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.pancard_img ?
                                                    <Link to={editableData?.pancard_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("pancard_img", {required: editableData?.pancard_img === null})}
                                                className="form-control"
                                            />
                                            {errors.pancard_img &&
                                                errors.pancard_img.type === "required" && (
                                                    <span className="text-danger">
                            PAN Image is required
                          </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                RC Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("rc_no", {required: true})}
                                                placeholder="RC Number"
                                            />
                                            {errors.rc_no && errors.rc_no.type === "required" && (
                                                <span className="text-danger">
                          RC Number is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload RC Image <span className="text-danger">*</span>
                                            </label>
                                            {
                                                editableData?.rc_img ?
                                                    <Link to={editableData?.rc_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("rc_img", {required: editableData?.rc_img === null})}
                                                className="form-control"
                                            />
                                            {errors.rc_img && errors.rc_img.type === "required" && (
                                                <span className="text-danger">
                          RC Image is required
                        </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MOA Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("moa_no")}
                                                placeholder="MOA Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MOA Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.moa_img ?
                                                    <Link to={editableData?.moa_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                {...register("moa_img")}
                                                className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                GST Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("gst_no")}
                                                placeholder="GST Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload GST Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.gst_img ?
                                                    <Link to={editableData?.gst_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("gst_img")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                MSME Number{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("msme_no")}
                                                placeholder="MSME Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>
                                                Upload MSME Image{" "}
                                                <span className="text-success">(Optional)</span>
                                            </label>
                                            {
                                                editableData?.msme_img ?
                                                    <Link to={editableData?.msme_img} target={"_blank"}>
                                                        View
                                                    </Link> : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                {...register("msme_img")}/>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            <button disabled={btnLoading} className="btn btn-dark btn-round">
                                {btnLoading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                                &nbsp;Save Changes
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditKYCDetails;
