import HolidayType from "../type/holidayType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  holidayList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  checkSunday: false,
};

const HolidayReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case HolidayType.GET_HOLIDAY_LIST:
      return {
        ...state,
        holidayList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case HolidayType.CREATE_HOLIDAY:
      return {
        ...state,
      };

    case HolidayType.UPDATE_HOLIDAY:
      return {
        ...state,
      };

    case HolidayType.REMOVE_HOLIDAY:
      return {
        ...state,
      };

    case HolidayType.CHECK_SUNDAY:
      return {
        ...state,
        checkSunday: action.payload,
      };

    default:
      return state;
  }
};

export default HolidayReducers;
