import { RootState } from "../";
import { ThunkAction } from "redux-thunk";
import AuthType from "../type/authType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const LogoutAction = {
  logout:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      dispatch({
        type: AuthType.RESET_AUTH,
      });
    },
};

export default LogoutAction;
