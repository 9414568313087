import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { EMPLOYER_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EmployeeType, {
  createEmployeeStore,
  GetEmployee,
  RemoveEmployee,
} from "../type/employeeType";
import toast from "react-hot-toast";
import DepartmentType, {
  CreateDepartment,
  RemoveDepartment,
} from "../type/departmentType";
import CreateEmployee from "../../views/employee/CreateEmployee";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EmployeeAction = {
  getEmployeeList:
    (data: GetEmployee): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "employee/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployeeType.GET_EMPLOYEE_LIST,
            payload: {
              data: result.data.data.employees.data,
              currentPage: result.data.data.employees.current_page,
              lastPage: result.data.data.employees.last_page,
              totalData: result.data.data.employees.total,
              from: result.data.data.employees.from,
              to: result.data.data.employees.to,
              totalApprovedKYC: result.data.data.totalApprovedKYC,
              totalPendingKYC: result.data.data.totalPendingKYC,
            },
          });
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  createEmployee:
    (data: createEmployeeStore): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      console.log({
        image: data.image,
        name: data.name,
        fatherName: data.fatherName,
        dateOfBirth: data.dateOfBirth,
        phone: data.phone,
        state: data.state,
        district: data.district,
        pinCode: data.pinCode,
        correspondenceAddress: data.correspondenceAddress,
        permanentAddress: data.permanentAddress,
        email: data.email,
        password: data.password,
        employeeId: data.employeeID,
        department: data.department,
        designation: data.designation,
        dateOfJoining: data.dateOfJoining,
        joiningSalary: data.joiningSalary,
        accountHolderName: data.accountHolderName,
        accountNumber: data.accountNumber,
        bankName: data.bankName,
        ifscCode: data.ifscCode,
        branch: data.branch,
      });

      // return false;
      try {
        const result: any = await axios.post(
          BASE_URL + "employee/create",

          {
            image: data.image,
            name: data.name,
            fatherName: data.fatherName,
            dateOfBirth: data.dateOfBirth,
            phone: data.phone,
            state: data.state,
            district: data.district,
            pinCode: data.pinCode,
            correspondenceAddress: data.correspondenceAddress,
            permanentAddress: data.permanentAddress,
            email: data.email,
            password: data.password,
            employeeId: data.employeeID,
            department: data.department,
            designation: data.designation,
            dateOfJoining: data.dateOfJoining,
            joiningSalary: data.joiningSalary,
            accountHolderName: data.accountHolderName,
            accountNumber: data.accountNumber,
            bankName: data.bankName,
            ifscCode: data.ifscCode,
            branch: data.branch,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployeeType.CREATE_EMPLOYEE,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  removeEmployee:
    (data: RemoveEmployee): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "employee/remove",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployeeType.REMOVE_EMPLOYEE,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default EmployeeAction;
