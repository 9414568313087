const DepartmentType = {
  CREATE_DEPARTMENT: "CREATE_DEPARTMENT",
  GET_DEPARTMENT_LIST: "GET_DEPARTMENT_LIST",
  REMOVE_DEPARTMENT: "REMOVE_DEPARTMENT",
  UPDATE_DEPARTMENT: "UPDATE_DEPARTMENT",
};

export interface CreateDepartment {
  name: string;
}

export interface GetDepartment {
  search: string;
  limit: number;
  page: number;
}

export interface UpdateDepartment {
  name: string;
  id: number;
}

export interface RemoveDepartment {
  id: number;
}

export default DepartmentType;
