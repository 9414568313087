const SalaryType = {
  SALARY_GENERATE_LIST: "SALARY_GENERATE_LIST",
  SALARY_PAYMENT_SLIP: "SALARY_PAYMENT_SLIP",
  GET_SALARY_STRUCTURE: "GET_SALARY_STRUCTURE",
  SUBMIT_SALARY_STRUCTURE: "SUBMIT_SALARY_STRUCTURE",
};

export interface SalaryGeneratedList {
  search: string;
  limit: number;
  page: number;
}

export interface SalaryPaymentSlipType {
  paymentSlip: any;
  employeeId: Array<string>;
  totalAmount: number;
  totalGstAmount: number;
  totalServiceFee: number;
  totalAmountReceivable: number;
}

export interface SalaryStructure {
  pf: number;
  esic: number;
  esicLimit: number;
  lateFeesPenalty: number;
  allowances: Array<string>;
  deductions: Array<string>;
}

export default SalaryType;
