import React, {useEffect} from "react";
import Header from "../include/Header";
import Sidebar from "../include/Sidebar";
import {useNavigate} from "react-router-dom";
import {EMPLOYER_TOKEN} from "../utils/Constants";
import {useDispatch} from "react-redux";
import AuthAction from "../stores/action/authAction";

interface Props {
    children: JSX.Element | string;
    pageName: string;
}

const WebLayout: React.FC<Props> = ({children: children, pageName}) => {
    const page = useNavigate();

    useEffect(() => {
        const tokens = localStorage.getItem(EMPLOYER_TOKEN);
        if (!tokens) {
            page("/");
        }
    }, [page]);

    const dispatch = useDispatch()<any>;

    const logoutHandle = async () => {
        try {
            const tokens: any = localStorage.getItem(EMPLOYER_TOKEN);
            await dispatch(AuthAction.logOut({tokens}));
            page("/");
        } catch (e) {
        }
    };

    return (
        <>
            <Header logoutHandle={logoutHandle}/>
            <Sidebar/>
            {children}
        </>
    );
};

export default WebLayout;
