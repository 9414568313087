import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PageRoutes from "./routes/PageRoutes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-right" reverseOrder={false} />
      <PageRoutes />
    </BrowserRouter>
  );
}

export default App;
