const HolidayType = {
  CREATE_HOLIDAY: "CREATE_HOLIDAY",
  GET_HOLIDAY_LIST: "GET_HOLIDAY_LIST",
  REMOVE_HOLIDAY: "REMOVE_HOLIDAY",
  UPDATE_HOLIDAY: "UPDATE_HOLIDAY",
  CHECK_SUNDAY: "CHECK_SUNDAY",
  MARK_SUNDAY: "MARK_SUNDAY",
};

export interface CreateHoliday {
  name: string;
  date: string;
}

export interface GetHoliday {
  search: string;
  limit: number;
  page: number;
  month: string;
}

export interface UpdateHoliday {
  name: string;
  date: string;
  id: number;
}

export interface RemoveHoliday {
  id: number;
}

export default HolidayType;
