const AttendanceType = {
  GET_ATTENDANCE_EMPLOYEES: "GET_ATTENDANCE_EMPLOYEES",
  SUBMIT_ATTENDANCE: "SUBMIT_ATTENDANCE",
  VIEW_ATTENDANCE: "VIEW_ATTENDANCE",
  EMPLOYEE_ATTENDANCE: "EMPLOYEE_ATTENDANCE",
};

export interface GetAttendanceType {
  date: string;
}

export interface ViewAttendance {
  search: string;
  limit: number;
  page: number;
  startDate: string;
  endDate: string;
}

export interface EmployeeAttendance {
  id: number;
  startDate: string;
  endDate: string;
}

export default AttendanceType;
