import React from "react";

const ImageComponent = ({ sourceImage, width, height }: any) => {
  return (
    <img
      src={
        sourceImage || "https://cdn-icons-png.flaticon.com/512/9261/9261181.png"
      }
      alt="Profile Image"
      width={width}
      height={height}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src =
          "https://cdn-icons-png.flaticon.com/512/9261/9261181.png";
      }}
    />
  );
};

export default ImageComponent;
