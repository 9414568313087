import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../stores";

type AttendanceRecord = {
  isOnLeave?: boolean;
  punchInTime?: string;
  punchOutTime?: string;
  leaveType?: string;
  leaveTypeId?: string;
  reason?: string;
};

type AttendanceRowProps = {
  employee: any; // replace 'any' with the appropriate type for employee
  onAttendanceChange: (employeeId: string, data: AttendanceRecord) => void;
};

const AttendanceRow: React.FC<AttendanceRowProps> = (props) => {
  const { fetchLeaveList } = useSelector((state: RootState) => state.leave);

  const [attendanceData, setAttendanceData] = useState<AttendanceRecord>({
    isOnLeave: false,
    punchInTime: "",
    punchOutTime: "",
    leaveType: "",
    leaveTypeId: "",
    reason: "",
  });

  useEffect(() => {
    if (props.employee.attendance_type === "LEAVE") {
      setAttendanceData({
        isOnLeave: true,
        leaveType: props.employee.leave_type,
        leaveTypeId: props.employee.leave_type_id,
        reason: props.employee.remarks,
      });
    } else {
      setAttendanceData({
        punchInTime: props.employee.in_time || "",
        punchOutTime: props.employee.out_time || "",
      });
    }
  }, [props.employee]);

  useEffect(() => {
    props.onAttendanceChange(props.employee.employeeId, attendanceData);
  }, [attendanceData]); // removed props from dependency array

  const handleLeaveToggle = (id: string) => {
    const newAttendance = {
      ...attendanceData,
      isOnLeave: !attendanceData.isOnLeave,
      punchInTime: "",
      punchOutTime: "",
      leaveType: "",
      leaveTypeId: "",
      reason: "",
    };
    setAttendanceData(newAttendance);
  };

  const handleTimeChange = (
    type: "punchInTime" | "punchOutTime",
    value: string,
  ) => {
    setAttendanceData((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleInputChange = (
    field: "leaveTypeId" | "reason",
    value: string,
  ) => {
    setAttendanceData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <tr key={props.employee.id}>
      <td width={"10%"}>{props.employee.employeeId}</td>
      <td width={"20%"}>{props.employee.name}</td>
      <td width={"15%"}>
        {!attendanceData.isOnLeave && (
          <>
            <input
              type="time"
              className="form-control form-control-sm me-2"
              value={attendanceData.punchInTime || ""}
              onChange={(e) => handleTimeChange("punchInTime", e.target.value)}
            />
          </>
        )}
      </td>
      <td width={"15%"}>
        {!attendanceData.isOnLeave && attendanceData.punchInTime && (
          <>
            <input
              type="time"
              className="form-control form-control-sm me-2"
              value={attendanceData.punchOutTime || ""}
              onChange={(e) => handleTimeChange("punchOutTime", e.target.value)}
            />
          </>
        )}
      </td>
      <td width={"10%"}>
        <input
          type="checkbox"
          checked={attendanceData.isOnLeave || false}
          onChange={() => handleLeaveToggle(props.employee.employeeId)}
        />
      </td>
      <td width={"15%"}>
        {attendanceData.isOnLeave && (
          <select
            className="form-control form-control-sm mb-2"
            value={attendanceData.leaveTypeId || ""}
            onChange={(e) => handleInputChange("leaveTypeId", e.target.value)}
          >
            <option value="" disabled>
              Select Type Of Leave
            </option>
            {fetchLeaveList.map((val: any, idx: number) => (
              <option key={idx} value={val.id}>
                {val.name}
              </option>
            ))}
          </select>
        )}
      </td>
      <td width={"15%"}>
        {attendanceData.isOnLeave && (
          <textarea
            className="form-control form-control-sm"
            rows={2}
            value={attendanceData.reason || ""}
            onChange={(e) => handleInputChange("reason", e.target.value)}
            placeholder={"Reason"}
          />
        )}
      </td>
    </tr>
  );
};

export default AttendanceRow;
