import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployeeAction from "../../stores/action/employeeAction";
import swal from "sweetalert";
import TableFilter from "../../components/TableFilter";
import moment from "moment/moment";
import Breadcrumb from "../../components/Breadcrumb";
import ImageComponent from "../../components/ImageComponent";

function EmployeeList() {
  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [type, setType] = useState<any>("VERIFIED");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);

  const dispatch = useDispatch<any>();

  const {
    employeeList,
    totalApprovedKYC,
    totalPendingKYC,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
  } = useSelector((state: RootState) => state.employee);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEmployeeList = useCallback(async () => {
    await dispatch(
      EmployeeAction.getEmployeeList({
        limit: limit,
        search: search,
        page: page,
        type: type,
      }),
    );
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList, search, limit, page, type]);

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(EmployeeAction.removeEmployee({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        getEmployeeList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <WebLayout pageName={"EmployeeList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"ASL Account"}
                subTitle={"ASL Account (Employee) List"}
              />
            </div>
          </div>
          <div className={"mb-2"}>
            <Link to={"../create-employee"}>
              <button className="btn btn-danger btn-sm">
                Create ASL Account (Employee)
              </button>
            </Link>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="profile-tab-box">
                  <ul className="nav">
                    {[1, 2].map((step) => (
                      <li
                        key={step}
                        className={`nav-item tab-all ${
                          currentStep === step ? "active-step border-0" : ""
                        }`}
                        style={{ width: `${50 / 2}%`, textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          onClick={() => {
                            setCurrentStep(step);
                            setType(step === 1 ? "VERIFIED" : "NOT VERIFIED");
                          }}
                        >
                          {`${
                            step === 1
                              ? `KYC APPROVED LIST (${totalApprovedKYC})`
                              : step === 2
                                ? `KYC PENDING LIST (${totalPendingKYC})`
                                : ""
                          }`}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <TableFilter
                  limit={limit}
                  set_limit={set_limit}
                  search={search}
                  set_search={set_search}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Employee Id</th>
                          <th> Image</th>
                          <th> Name</th>
                          <th> Communication</th>
                          <th> Address</th>
                          <th> Dept./Designation</th>
                          <th> Joining Date</th>
                          <th> KYC Status</th>
                          <th> Account Status</th>
                          <th> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeList?.length > 0 ? (
                          employeeList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {dataList.emp_no ? dataList.emp_no : "N/A"}
                                </td>
                                <td>
                                  <ImageComponent
                                    sourceImage={dataList.profile_img}
                                    width={80}
                                    height={80}
                                  />
                                </td>
                                <td>{dataList.name}</td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Mobile :{" "}
                                  </span>{" "}
                                  {dataList.mobile}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Email :{" "}
                                  </span>{" "}
                                  {dataList.email}
                                </td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Address :{" "}
                                  </span>{" "}
                                  {dataList.present_address}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    State :{" "}
                                  </span>{" "}
                                  {dataList.sName}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    District :{" "}
                                  </span>{" "}
                                  {dataList.dName} <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    Pincode :{" "}
                                  </span>{" "}
                                  {dataList.pincode}
                                </td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Department :{" "}
                                  </span>{" "}
                                  {dataList.departmentName
                                    ? dataList.departmentName
                                    : "N/A"}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Designation :{" "}
                                  </span>{" "}
                                  {dataList.designationName
                                    ? dataList.designationName
                                    : "N/A"}
                                </td>
                                <td>{dataList.doj ? dataList.doj : "N/A"}</td>
                                <td>
                                  {dataList?.kyc_status === "VERIFIED" ? (
                                    <a className="btn btn-success btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : dataList?.kyc_status ===
                                    "SEND FOR APPROVAL" ? (
                                    <a className="btn btn-warning btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : dataList?.kyc_status ===
                                    "NOT VERIFIED" ? (
                                    <a className="btn bg-purple btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : dataList?.kyc_status === "REJECTED" ? (
                                    <a className="btn btn-danger btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <button
                                    className={`btn btn-sm ${dataList.employee_status === "PENDING" ? "btn-warning" : dataList.employee_status === "ACTIVE" ? "btn-info" : "btn-danger"}`}
                                    style={{ borderRadius: "0 !important" }}
                                  >
                                    {dataList.employee_status}
                                  </button>
                                </td>
                                <td>
                                  <Link
                                    to={`../employee-profile/${dataList.id}`}
                                  >
                                    <button
                                      className={"btn btn-sm btn-success"}
                                      style={{ borderRadius: "0 !important" }}
                                    >
                                      View
                                    </button>
                                  </Link>
                                  &nbsp;
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleRemove(dataList.id)}
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <i className="fa fa-spinner fa-spin"></i>
                                    ) : (
                                      "Remove"
                                    )}
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={11} className={"text-center"}>
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {employeeList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p: any, index: number) => {
                              return (
                                <li
                                  key={index}
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}
                                >
                                  <a
                                    className="page-link"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0"
                                  >
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeList;
