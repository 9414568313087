import React, { useCallback, useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import moment from "moment";
import AttendanceAction from "../../stores/action/attendanceAction";
import { today } from "../../utils/Constants";
import AttendanceRow from "../../components/AttendanceRow";
import { Link } from "react-router-dom";
import LeaveAction from "../../stores/action/leaveAction";
import Breadcrumb from "../../components/Breadcrumb";

function Attendance() {
  const [selectedDate, setSelectedDate] = useState<string>(today);
  const [attendanceData, setAttendanceData] = useState<any>({}); // Track all attendance data here

  const dispatch = useDispatch<any>();

  const { attendanceEmployeeList } = useSelector(
    (state: RootState) => state.attendance,
  );

  const getAttendance = useCallback(async () => {
    await dispatch(
      AttendanceAction.getAttendanceEmployees({ date: selectedDate }),
    );
    await dispatch(LeaveAction.leaveFetch());
  }, [dispatch, selectedDate]);

  useEffect(() => {
    getAttendance();
  }, [getAttendance]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  // Function to update attendanceData from child component
  const updateAttendanceData = (employeeId: string, data: any) => {
    setAttendanceData((prevData: any) => ({
      ...prevData,
      [employeeId]: data,
    }));
  };

  // Function to handle submission of all attendance
  const handleSubmitAttendance = async () => {
    try {
      // Create the payload in the required format
      const formattedAttendanceData = Object.keys(attendanceData).map(
        (employeeId) => {
          const data = attendanceData[employeeId];
          const isOnLeave = data.isOnLeave;
          return {
            employeeId: employeeId,
            date: selectedDate,
            punchInTime: isOnLeave ? null : data.punchInTime || null,
            punchOutTime: isOnLeave ? null : data.punchOutTime || null,
            inLocation: null,
            outLocation: null,
            attendanceType: isOnLeave ? "LEAVE" : "PRESENT",
            leaveTypeId: isOnLeave ? data.leaveTypeId : "",
            reason: isOnLeave ? data.reason : "",
          };
        },
      );

      // Submit the collected attendance data
      // Uncomment and modify the line below to integrate with the backend submission
      await dispatch(
        AttendanceAction.submitAttendance(formattedAttendanceData),
      );
      getAttendance();
    } catch (error) {
      console.error("Error submitting attendance:", error);
      alert("Failed to submit attendance.");
    }
  };

  return (
    <WebLayout pageName={"Attendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Attendance"} subTitle={"Mark Attendance"} />
            <div className="row">
              <div className="col-2">
                <label>Select Date:</label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  max={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{
                    backgroundColor: "white",
                    padding: "0px 10px 0px 10px",
                    border: "1px solid",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-8">
              <div className="card">
                <div className="header">
                  <h2>
                    Mark Attendance on{" "}
                    {moment(selectedDate).format("DD-MMM-YYYY")}
                  </h2>
                </div>

                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Employee Id</th>
                          <th>Name</th>
                          <th>Punch In Time</th>
                          <th>Punch Out Time</th>
                          <th>On Leave</th>
                          <th>Type Of Leave</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceEmployeeList?.length > 0 ? (
                          attendanceEmployeeList.map((employee: any) => (
                            <AttendanceRow
                              key={employee.employeeId}
                              employee={employee}
                              onAttendanceChange={updateAttendanceData} // Pass down the update function
                            />
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No Employees Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Submit Button */}
                  <button
                    className="btn btn-primary mt-4"
                    onClick={handleSubmitAttendance}
                  >
                    Submit Attendance
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Attendance;
