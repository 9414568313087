const LeaveType = {
  CREATE_LEAVE: "CREATE_LEAVE",
  GET_LEAVE_LIST: "GET_LEAVE_LIST",
  REMOVE_LEAVE: "REMOVE_LEAVE",
  UPDATE_LEAVE: "UPDATE_LEAVE",
  FETCH_LEAVE_LIST: "FETCH_LEAVE_LIST",
  GET_LEAVE_REQUEST_LIST: "GET_LEAVE_REQUEST_LIST",
  UPDATE_LEAVE_REQUEST: "UPDATE_LEAVE_REQUEST",
};

export interface CreateLeave {
  name: string;
}

export interface GetLeave {
  search: string;
  limit: number;
  page: number;
}

export interface UpdateLeave {
  name: string;
  id: number;
}

export interface RemoveLeave {
  id: number;
}

export interface GetLeaveRequest {
  search: string;
  limit: number;
  page: number;
  type: string;
}

export interface UpdateLeaveRequest {
  status: string;
  id: number;
}

export default LeaveType;
