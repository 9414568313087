import EmployerType from "../type/employerType";

export interface ReducerAction {
    type: string;
    payload?: any;
}

const INITIAL_STATE = {
    employerDetails: {},
};

const EmployerReducers = (
    state = INITIAL_STATE,
    action: ReducerAction
) => {
    switch (action.type) {
        case EmployerType.UPDATE_EMPLOYER:
            return {
                ...state,
            };

        case EmployerType.UPDATE_EMPLOYER_KYC:
            return {
                ...state,
            };

        case EmployerType.GET_EMPLOYER_DETAILS:
            return {
                ...state,
                employerDetails: action.payload,
            };

        case EmployerType.CHANGE_PASSWORD:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default EmployerReducers;
