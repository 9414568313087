import { Link } from "react-router-dom";

function Error() {
  return (
    <div className="limiter">
      <link
        href="https://employer.abhhyamsecure.com/assets/css/pages/extra_pages.css"
        rel="stylesheet"
      />
      <div className="container-login100">
        <div className="wrap-login100" style={{ justifyContent: "center" }}>
          <div className="login100-form">
            <span className="error-header p-b-45">404</span>
            <span className="error-subheader p-b-5">
              Looks Like You're Lost
            </span>
            <span className="error-subheader2 p-b-5">
              The Page You Are Looking For Not Available!
            </span>
            <div className="container-login100-form-btn p-t-30	">
              <Link to={"./"} className="login100-form-btn">
                Go To Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
