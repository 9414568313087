import DepartmentType from "../type/departmentType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  departmentList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const DepartmentReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case DepartmentType.GET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case DepartmentType.CREATE_DEPARTMENT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default DepartmentReducers;
