import React from "react";

const TableFilter = ({ limit, set_limit, search, set_search }: any) => (
  <div className="row g-3 px-4 mb-2 align-items-end">
    <div className="col-md-2">
      <label className="mb-2 mt-2">Show Entries</label>
      <select
        onChange={(e) => set_limit(parseInt(e.target.value, 10))}
        id="defaultSelect"
        className="form-select"
        value={limit}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div className="col-md-8"></div>
    <div className="col-md-2">
      <div className="input-group input-group-merge mb-1">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={search}
          onChange={(e) => set_search(e.target.value)}
        />
      </div>
    </div>
  </div>
);

export default TableFilter;
