const ProfileType = {
  SET_PROFILE_DATA: "SET_PROFILE_DATA",
  UPDATE_PROFILE_DATA: "UPDATE_PROFILE_DATA",
  UPDATE_BANK_DETAILS: "UPDATE_BANK_DETAILS",
  UPDATE_KYC_DETAILS: "UPDATE_KYC_DETAILS",
  GET_STATE_LIST: "GET_STATE_LIST",
  GET_DISTRICT_LIST: "GET_DISTRICT_LIST",
};

export interface UpdateProfile {
  id: number;
  profile_img: any;
  name: string;
  f_name: string;
  email: string;
  mobile: string;
  state: number;
  district: number;
  pincode: string;
  present_address: string;
  permanent_address: string;
}

export interface UpdateBankDetails {
  id: number;
  account_holder_name: string;
  account_number: string;
  bank_name: string;
  branch_name: string;
  ifsc_code: string;
}

export interface UpdateKYCDetails {
  id: number;
  pan_img: any;
  pan_no: string;
  aadhar_img: any;
  aadhar_no: any;
}

export interface GetDistrict {
  state_id: number;
}

export interface GetEmployeeProfile {
  id: number;
}

export default ProfileType;
