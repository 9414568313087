import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import AuthType, { LogIn, LogOut } from "../type/authType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const AuthAction = {
  logIn:
    (data: LogIn): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const user: any = await axios.post(BASE_URL + "login", {
          username: data.username,
          password: data.password,
        });

        if (user.data.status) {
          const payload = {
            id: user.data.data.id,
            name: user.data.data.name,
            email: user.data.data.email,
            mobile: user.data.data.mobile,
            username: user.data.data.username,
          };

          dispatch({
            type: AuthType.SET_USER,
            payload: payload,
            token: user.data.token,
          });
        } else {
          const errorMsg = "Invalid Login Credentials";
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  logOut:
    (data: LogOut): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const result: any = await axios.get(BASE_URL + "logout", {
          headers: {
            Authorization: "Bearer " + data.tokens.replaceAll('"', ""),
          },
        });
        if (result.data.status) {
          dispatch({
            type: AuthType.RESET_AUTH,
          });
        }
      } catch (error: any) {
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default AuthAction;
