import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb";
import SalaryAction from "../../stores/action/salaryAction";
import { RootState } from "../../stores";

interface Allowance {
  name: string;
  amount: string;
}

interface Deduction {
  name: string;
  amount: string;
}

function SalaryStructure() {
  const dispatch = useDispatch<any>();
  const { salaryStructure } = useSelector((state: RootState) => state.salary);

  const [pf, setPf] = useState<string>("");
  const [esic, setEsic] = useState<string>("");
  const [esicLimit, setEsicLimit] = useState<string>("");
  const [lateFeesPenalty, setLateFeesPenalty] = useState<string>("");
  const [allowances, setAllowances] = useState<Allowance[]>([]);
  const [deductions, setDeductions] = useState<Deduction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleAddAllowances = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAllowances([...allowances, { name: "", amount: "" }]);
  };

  const handleRemoveAllowances = (index: number) => {
    if (allowances.length > 1) {
      const newAllowances = allowances.filter((_, i) => i !== index);
      setAllowances(newAllowances);
    } else {
      // Empty the row instead of removing it
      const newAllowances = [...allowances];
      newAllowances[0] = { name: "", amount: "" }; // Reset the first allowance
      setAllowances(newAllowances);
    }
  };

  const handleAddDeductions = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDeductions([...deductions, { name: "", amount: "" }]);
  };

  const handleRemoveDeductions = (index: number) => {
    if (deductions.length > 1) {
      const newDeductions = deductions.filter((_, i) => i !== index);
      setDeductions(newDeductions);
    } else {
      // Empty the row instead of removing it
      const newDeductions = [...deductions];
      newDeductions[0] = { name: "", amount: "" }; // Reset the first deduction
      setDeductions(newDeductions);
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!pf || isNaN(Number(pf)))
      newErrors.pf = "Please enter a valid PF percentage.";
    if (!esic || isNaN(Number(esic)))
      newErrors.esic = "Please enter a valid ESIC percentage.";
    if (!esicLimit || isNaN(Number(esicLimit)))
      newErrors.esicLimit = "Please enter a valid ESIC limit.";
    if (!lateFeesPenalty || isNaN(Number(lateFeesPenalty)))
      newErrors.lateFeesPenalty = "Please enter a valid late fees penalty.";

    allowances.forEach((allowance, index) => {
      if (!allowance.name)
        newErrors[`allowanceName${index}`] = "Allowance name is required.";
      if (!allowance.amount || isNaN(Number(allowance.amount)))
        newErrors[`allowanceAmount${index}`] =
          "Valid allowance amount is required.";
    });

    deductions.forEach((deduction, index) => {
      if (!deduction.name)
        newErrors[`deductionName${index}`] = "Deduction name is required.";
      if (!deduction.amount || isNaN(Number(deduction.amount)))
        newErrors[`deductionAmount${index}`] =
          "Valid deduction amount is required.";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getSalaryData = useCallback(async () => {
    await dispatch(SalaryAction.getSalaryStructure());
  }, [dispatch]);

  useEffect(() => {
    getSalaryData();
  }, [getSalaryData]);

  useEffect(() => {
    if (salaryStructure) {
      setPf(salaryStructure.pf ? salaryStructure.pf : "");
      setEsic(salaryStructure.esic ? salaryStructure.esic : "");
      setEsicLimit(
        salaryStructure.esic_limit ? salaryStructure.esic_limit : "",
      );
      setLateFeesPenalty(
        salaryStructure.late_fees_penalty
          ? salaryStructure.late_fees_penalty
          : "",
      );
      setAllowances(
        salaryStructure.allowances
          ? JSON.parse(salaryStructure.allowances)
          : [{ name: "", amount: "" }],
      );
      setDeductions(
        salaryStructure.deductions
          ? JSON.parse(salaryStructure.deductions)
          : [{ name: "", amount: "" }],
      );
    }
  }, [salaryStructure]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const salaryData: any = {
      pf,
      esic,
      esicLimit,
      lateFeesPenalty,
      allowances,
      deductions,
    };

    try {
      await dispatch(SalaryAction.submitSalaryStructure(salaryData));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <WebLayout pageName={"SalaryStructure"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Salary"} subTitle={"Salary Structure"} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>
                                PF (in Percentage){" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="PF (in Percentage)"
                                value={pf}
                                onChange={(e) =>
                                  setPf(e.target.value.replace(/[^0-9.]/g, ""))
                                }
                                maxLength={2}
                              />
                              {errors.pf && (
                                <div className="text-danger">{errors.pf}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>
                                ESIC (in Percentage){" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="ESIC (in Percentage)"
                                value={esic}
                                onChange={(e) =>
                                  setEsic(
                                    e.target.value.replace(/[^0-9.]/g, ""),
                                  )
                                }
                                maxLength={2}
                              />
                              {errors.esic && (
                                <div className="text-danger">{errors.esic}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>
                                ESIC Limit{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="ESIC Limit"
                                value={esicLimit}
                                onChange={(e) =>
                                  setEsicLimit(
                                    e.target.value.replace(/[^0-9.]/g, ""),
                                  )
                                }
                              />
                              {errors.esicLimit && (
                                <div className="text-danger">
                                  {errors.esicLimit}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label>
                                Late Fees Penalty (in Rupees){" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Late Fees Penalty (in Rupees)"
                                value={lateFeesPenalty}
                                onChange={(e) =>
                                  setLateFeesPenalty(
                                    e.target.value.replace(/[^0-9.]/g, ""),
                                  )
                                }
                              />
                              {errors.lateFeesPenalty && (
                                <div className="text-danger">
                                  {errors.lateFeesPenalty}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className={"col-lg-6"}>
                            <div
                              className={
                                "d-flex align-items-center justify-content-between"
                              }
                            >
                              <h5>Allowances</h5>
                              <button
                                className={"btn btn-sm btn-outline-success"}
                                onClick={handleAddAllowances}
                              >
                                Add Allowance
                              </button>
                            </div>
                            {allowances.map((allowance, index) => (
                              <div key={index} className={"row"}>
                                <div className="col-lg-5">
                                  <div className="form-group">
                                    <label>
                                      Allowance Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Allowance Name"
                                      value={allowance.name}
                                      onChange={(e) => {
                                        const newAllowances = [...allowances];
                                        newAllowances[index].name =
                                          e.target.value;
                                        setAllowances(newAllowances);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-5">
                                  <div className="form-group">
                                    <label>
                                      Amount{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Amount"
                                      value={allowance.amount}
                                      onChange={(e) => {
                                        const newAllowances = [...allowances];
                                        newAllowances[index].amount =
                                          e.target.value.replace(
                                            /[^0-9.]/g,
                                            "",
                                          );
                                        setAllowances(newAllowances);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 d-flex justify-content-center align-items-center">
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() =>
                                      handleRemoveAllowances(index)
                                    }
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className={"col-lg-6"}>
                            <div
                              className={
                                "d-flex align-items-center justify-content-between"
                              }
                            >
                              <h5>Deductions</h5>
                              <button
                                className={"btn btn-sm btn-outline-danger"}
                                onClick={handleAddDeductions}
                              >
                                Add Deduction
                              </button>
                            </div>
                            {deductions.map((deduction, index) => (
                              <div key={index} className={"row"}>
                                <div className="col-lg-5">
                                  <div className="form-group">
                                    <label>
                                      Deduction Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Deduction Name"
                                      value={deduction.name}
                                      onChange={(e) => {
                                        const newDeductions = [...deductions];
                                        newDeductions[index].name =
                                          e.target.value;
                                        setDeductions(newDeductions);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-5">
                                  <div className="form-group">
                                    <label>
                                      Amount{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Amount"
                                      value={deduction.amount}
                                      onChange={(e) => {
                                        const newDeductions = [...deductions];
                                        newDeductions[index].amount =
                                          e.target.value.replace(
                                            /[^0-9.]/g,
                                            "",
                                          );
                                        setDeductions(newDeductions);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 d-flex justify-content-center align-items-center">
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      handleRemoveDeductions(index)
                                    }
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </WebLayout>
  );
}

export default SalaryStructure;
