import {Link} from "react-router-dom";

function ViewKYCDetails({editableData}: any) {

    return (
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                        <h2>
                            <strong>KYC</strong> DOCUMENT
                        </h2>
                    </div>
                    {editableData?.type_of_company === "Proprietor" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : <tr>
                                                <th>PAN NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PAN IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : <tr>
                                                <th>GST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>GST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : <tr>
                                                <th>MSME NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MSME IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : editableData?.type_of_company ===
                    "Partnership Firm" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : <tr>
                                                <th>PAN NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PAN IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : <tr>
                                                <th>GST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>GST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.partnership_no ? <tr>
                                                <th>PARTNERSHIP NUMBER</th>
                                                <td>{editableData.partnership_no}</td>
                                            </tr> : <tr>
                                                <th>PARTNERSHIP NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.partnership_img ? <tr>
                                                <th>PARTNERSHIP IMAGE</th>
                                                <td>
                                                    <Link to={editableData.partnership_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.partnership_img}
                                                            alt="PARTNERSHIP Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PARTNERSHIP IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : <tr>
                                                <th>MSME NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MSME IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : editableData?.type_of_company === "LLP" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : <tr>
                                                <th>PAN NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PAN IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : <tr>
                                                <th>GST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>GST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.rc_no ? <tr>
                                                <th>RC NUMBER</th>
                                                <td>{editableData.rc_no}</td>
                                            </tr> : <tr>
                                                <th>RC NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.rc_img ? <tr>
                                                <th>RC IMAGE</th>
                                                <td>
                                                    <Link to={editableData.rc_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.rc_img}
                                                            alt="RC Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>RC IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.partnership_no ? <tr>
                                                <th>PARTNERSHIP NUMBER</th>
                                                <td>{editableData.partnership_no}</td>
                                            </tr> : <tr>
                                                <th>PARTNERSHIP NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.partnership_img ? <tr>
                                                <th>PARTNERSHIP IMAGE</th>
                                                <td>
                                                    <Link to={editableData.partnership_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.partnership_img}
                                                            alt="Partnership Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PARTNERSHIP IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : <tr>
                                                <th>MSME NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MSME IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : editableData?.type_of_company === "Company" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : <tr>
                                                <th>PAN NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PAN IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : <tr>
                                                <th>GST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>GST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.moa_no ? <tr>
                                                <th>MOA NUMBER</th>
                                                <td>{editableData.moa_no}</td>
                                            </tr> : <tr>
                                                <th>MOA NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.moa_img ? <tr>
                                                <th>MOA IMAGE</th>
                                                <td>
                                                    <Link to={editableData.moa_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.moa_img}
                                                            alt="MOA Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MOA IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.aoa_no ? <tr>
                                                <th>AOA NUMBER</th>
                                                <td>{editableData.aoa_no}</td>
                                            </tr> : <tr>
                                                <th>AOA NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.aoa_img ? <tr>
                                                <th>AOA IMAGE</th>
                                                <td>
                                                    <Link to={editableData.aoa_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.aoa_img}
                                                            alt="AOA Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>AOA IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.coi_no ? <tr>
                                                <th>COI NUMBER</th>
                                                <td>{editableData.coi_no}</td>
                                            </tr> : <tr>
                                                <th>COI NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.coi_img ? <tr>
                                                <th>COI IMAGE</th>
                                                <td>
                                                    <Link to={editableData.coi_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.coi_img}
                                                            alt="COI Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>COI IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : <tr>
                                                <th>MSME NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MSME IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : editableData?.type_of_company ===
                    "Any other entity" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : null
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : null
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : null
                                        }

                                        {
                                            editableData.coi_no ? <tr>
                                                <th>COI NUMBER</th>
                                                <td>{editableData.coi_no}</td>
                                            </tr> : null
                                        }
                                        {
                                            editableData.coi_img ? <tr>
                                                <th>COI IMAGE</th>
                                                <td>
                                                    <Link to={editableData.coi_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.coi_img}
                                                            alt="COI Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : null
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : null
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : null
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : editableData?.type_of_company === "Trust" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : <tr>
                                                <th>PAN NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PAN IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : <tr>
                                                <th>GST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>GST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.trust_no ? <tr>
                                                <th>TRUST NUMBER</th>
                                                <td>{editableData.trust_no}</td>
                                            </tr> : <tr>
                                                <th>TRUST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.trust_img ? <tr>
                                                <th>TRUST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.trust_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.trust_img}
                                                            alt="Trust Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>TRUST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : <tr>
                                                <th>MSME NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MSME IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : editableData?.type_of_company === "Society" ? (
                        <div className="body">
                            <div className="row">
                                <div className={"col-12"}>
                                    <table className={"table table-bordered"}>
                                        {
                                            editableData.pancard_no ? <tr>
                                                <th>PAN NUMBER</th>
                                                <td>{editableData.pancard_no}</td>
                                            </tr> : <tr>
                                                <th>PAN NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.pancard_img ? <tr>
                                                <th>PAN IMAGE</th>
                                                <td>
                                                    <Link to={editableData.pancard_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.pancard_img}
                                                            alt="PAN Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>PAN IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.rc_no ? <tr>
                                                <th>RC NUMBER</th>
                                                <td>{editableData.rc_no}</td>
                                            </tr> : <tr>
                                                <th>RC NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.rc_img ? <tr>
                                                <th>RC IMAGE</th>
                                                <td>
                                                    <Link to={editableData.rc_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.rc_img}
                                                            alt="RC Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>RC IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.moa_no ? <tr>
                                                <th>MOA NUMBER</th>
                                                <td>{editableData.moa_no}</td>
                                            </tr> : <tr>
                                                <th>MOA NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.moa_img ? <tr>
                                                <th>MOA IMAGE</th>
                                                <td>
                                                    <Link to={editableData.moa_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.moa_img}
                                                            alt="MOA Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MOA IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_no ? <tr>
                                                <th>GST NUMBER</th>
                                                <td>{editableData.gst_no}</td>
                                            </tr> : <tr>
                                                <th>GST NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.gst_img ? <tr>
                                                <th>GST IMAGE</th>
                                                <td>
                                                    <Link to={editableData.gst_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.gst_img}
                                                            alt="GST Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>GST IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }

                                        {
                                            editableData.msme_no ? <tr>
                                                <th>MSME NUMBER</th>
                                                <td>{editableData.msme_no}</td>
                                            </tr> : <tr>
                                                <th>MSME NUMBER</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                        {
                                            editableData.msme_img ? <tr>
                                                <th>MSME IMAGE</th>
                                                <td>
                                                    <Link to={editableData.msme_img} target={"_blank"}>
                                                        <img
                                                            className="img-responsive img-center"
                                                            src={editableData.msme_img}
                                                            alt="MSME Image"
                                                            width={100}
                                                        />
                                                    </Link>
                                                </td>
                                            </tr> : <tr>
                                                <th>MSME IMAGE</th>
                                                <td>N/A</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default ViewKYCDetails;
