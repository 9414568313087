const EmployerType = {
    GET_EMPLOYER_DETAILS: "GET_EMPLOYER_DETAILS",
    UPDATE_EMPLOYER: "UPDATE_EMPLOYER",
    UPDATE_EMPLOYER_BANK_DETAILS: "UPDATE_EMPLOYER_BANK_DETAILS",
    UPDATE_EMPLOYER_KYC: "UPDATE_EMPLOYER_KYC",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export interface UpdateBasicDetails {
    companyImage: any;
    name: string;
    no_of_employee: number;
    email: string;
    mobile: any;
    typeOfCompany: any;
    state: number;
    district: number;
    pincode: number;
    address: string;
}

export interface UpdateBankDetails {
    bankName: any;
    branchName: any;
    accountHolderName: any;
    ifscCode: any;
    accountNumber: any;
}

export interface UpdateEmployerKYC {
    id: number;
    gst_no: any;
    gst_img: any;
    pancard_no: any;
    pancard_img: any;
    msme_no: any;
    msme_img: any;
    partnership_no: any;
    partnership_img: any;
    rc_no: any;
    rc_img: any;
    moa_no: any;
    moa_img: any;
    aoa_no: any;
    aoa_img: any;
    coi_no: any;
    coi_img: any;
    trust_no: any;
    trust_img: any;
}

export interface ChangePassword {
    currentPassword: any;
    newPassword: any;
    confirmPassword: any;
}

export default EmployerType;
